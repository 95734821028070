import React from 'react';
import { graphql } from 'gatsby';
import Layout from '@common/Layout';
import Navbar from '@common/Navbar';
import Footer from '@sections/Footer';
import Post from '@article/Post/post';

const PostTemplate = ({ data }) => {
  const { mdx } = data;
  let coverImageSource;
  if (mdx.fields) {
    coverImageSource = mdx.fields.coverImageRelative;
  }
  return (
    <Layout title={mdx.frontmatter.title}>
      <Navbar hideMenu={true} />
      <Post post={mdx} coverImage={coverImageSource} />
      <Footer />
    </Layout>
  );
};

export const query = graphql`
  query ($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      body
      frontmatter {
        date(formatString: "MMMM YYYY")
        slug
        title
        excerpt
        tag
      }
      fields {
        coverImageRelative {
          childImageSharp {
            gatsbyImageData(width: 960, layout: CONSTRAINED)
          }
          name
        }
      }
      timeToRead
    }
  }
`;

export default PostTemplate;
